import { Component, Prop, Watch } from 'vue-property-decorator';
import { CategoryModule } from '@/store/category';
import Globals from '@/globals';
import PlayerUtil from '@/support/playerUtil';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';
import { StudioModule } from '@/store/studio';

@Component
export default class AddNewStudioComponent extends StudioPageBase {

    model = Globals.NewStudio;

    errors: string[] = [];

    category: SUR.CategoryDto = {
        id: 0,
        name: null,
        order: 0,
        disabled: false,
    };

    loading = true;
    loaded = false;
    busy = false;

    get categories() {
        return CategoryModule.categories;
    }

    saveDisabled: boolean = true;

    options = {
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    @Watch('model.studio', { immediate: true })
    onStudioChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('model.title', { immediate: true })
    onTitleChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    @Watch('category', { immediate: true })
    onCategoryChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    updateButtons() {
        if (!this.model || !this.model.studio || !this.model.title ||
            !this.model.studio.trim() || !this.model.title.trim())
            this.saveDisabled = true;
        else
            this.saveDisabled = false;
    }

    created() {
        Debug.setDebugModule('AddNewStudio', this);
        this.category = this.getStudioCategory();
        this.loaded = true;
        this.loading = false;

        super.created('addNewStudio');
    }

    mounted() {
        super.mounted();

        this.parent = this.wizard.parent;
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    validateInput() {
        this.errors.splice(0, this.errors.length);

        if (!this.model.title)
            this.errors.push('Friendly name required');
        else if (this.model.studio != Util.sanitizeSentence(this.model.studio))
            this.errors.push('Invalid Friendly name - no funny characters.');

        if (!this.model.studio)
            this.errors.push('Alias required');
        else if (this.model.studio != Util.sanitizeId(this.model.studio))
            this.errors.push('Invalid Alias: letters and number only.');

        if (!this.category)
            this.errors.push('Category required');
        else if (!this.category.id || !this.category.name)
            this.errors.push('Invalid category');

        return !this.errors.length;
    }

    async done() {
        if (!this.wizard) return false;
        if (!this.validateInput()) return false;

        this.saveDisabled = true;
        this.busy = true;
        let categoryName = this.category ? this.category.name : null;
        let category = this.categories.find(item => item.name == categoryName);
        if (!category) {
            Debug.error('Missing category', categoryName, this.categories);
            Util.showError('Missing category');
            return;
        }
        try {
            this.model.category = category.name;
            this.model.categoryId = category.id;
            this.model.categoryOrder = category.order;

            // Store sometime double creates
            let createdStudio = await StudioModule.createStudio(this.model);

            Debug.log('addNewStudio done SUCCEEDED');
            Util.showToast('Saved portal details ' + this.model.title);

            setTimeout(() => {
                window.sessionStorage.setItem('options.settings', '1');
                PlayerUtil.redirectToStudio(this.$router, createdStudio.studio);
            }, 1 * 1000);

            return null;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("addNewStudio FAILED", this.model.studio, message);
            Util.showToast('Failed to add new portal ' + this.model.title, true);
            this.busy = false;
            this.saveDisabled = false;
            return null;
        } finally {

        }
    }

    cancel() {
        if (!this.wizard) return;
        this.wizard.back();
    }

    manageCategories() {
        if (!this.wizard) return;

        this.wizard.navigateTo('categories');
    }

}

