import { render, staticRenderFns } from "./addNewStudio.vue?vue&type=template&id=0de60d6b"
import script from "./addNewStudio.ts?vue&type=script&lang=js&external"
export * from "./addNewStudio.ts?vue&type=script&lang=js&external"
import style0 from "./addNewStudio.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports